#ace-editor {
  height: ~"calc(100vh - 51px)" !important;
}

.SplitPane {
  height: ~"calc(100% - 51px)" !important;
}

.dropzone {
  height: 100%;
  width: 100%;

  .dropzone__overlay {
    padding-top: 20px;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    background: #2D2D2D;
    text-align: center;
    color: #fff;
    font-size: 1rem;
  }
}

.swagger-ui {
  // ensure Swagger-UI uses its entire container
  height: 100%;

  .version-pragma {
    // make the version pragma message look nicer in the context
    // of the Editor
    font-size: 1.2em;
  }
}

@media print {
  //List of elements that should not be rendered for printing
  .Pane1, .topbar {
    display: none;
  }

  .SplitPane {
    position: relative !important;
    display: block !important;
  }

  //The right-hand preview pane should be the full width of the page
  .Pane2 {
    overflow-y: auto;
    width: 100% !important;
  }
}
